import React from 'react';
import PropTypes from 'prop-types';

import { AddonOptionValuePropType } from '../typedef';

const AddonOptionValues = ({ data, condensed, isOrderLine }) => {
  if (condensed)
    return (
      <small>
        {data
          .map((addonOptionValue) => addonOptionValue.label)
          .join(', ')}
      </small>
    );

  return (
    <ul style={{ listStyle: 'none' }}>
      {data.map((addonOptionValue) => {
        return (
          <li
            key={addonOptionValue.id}
            className={
              isOrderLine
                ? 'tw-text-white-400 tw-leading-snug'
                : undefined
            }
          >
            {addonOptionValue.label}
          </li>
        );
      })}
    </ul>
  );
};

AddonOptionValues.propTypes = {
  data: PropTypes.arrayOf(AddonOptionValuePropType),
  condensed: PropTypes.bool,
};

AddonOptionValues.defaultProps = {
  data: [],
  condensed: false,
};

export default AddonOptionValues;
