import datetime, {
  formatDate,
  formatDatetime,
  formatTime,
  getDaysBetween,
  objectToZonedTime,
} from 'lib/datetime';
import { DELIVERY_TIMESLOT_DURATION_HOURS } from './constants';

const days = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
};

const timeslotsByDay = {
  [days.sun]: [{ hours: 19, minutes: 0 }],
  [days.mon]: [
    { hours: 10, minutes: 0 },
    { hours: 19, minutes: 0 },
  ],
  [days.tue]: [],
  [days.wed]: [{ hours: 19, minutes: 0 }],
  [days.thu]: [
    { hours: 10, minutes: 0 },
    { hours: 19, minutes: 0 },
  ],
  [days.fri]: [],
  [days.sat]: [],
};

const availableDeliveryDays = [
  days.sun,
  days.mon,
  days.wed,
  days.thu,
];

export const getDeliveryDates = ({ start, end }) => {
  let deliveryDates = [];

  availableDeliveryDays.forEach((deliveryDay) => {
    const days = getDaysBetween({
      start,
      end,
      day: deliveryDay,
    });

    deliveryDates = [...deliveryDates, ...days];
  });

  return deliveryDates;
};

export const getDeliveryTimesByDate = (date, { timezone } = {}) => {
  if (!date) return [];
  const dayOfWeek = datetime(date).day();

  const deliveryDayObj = datetime(date).toObject();

  const timeslots = timeslotsByDay[dayOfWeek];

  return timeslots.map(({ hours, minutes }) => {
    const timeslotObj = {
      ...deliveryDayObj,
      hours,
      minutes,
      seconds: 0,
      milliseconds: 0,
    };

    return objectToZonedTime(timeslotObj, timezone);
  });
};

const availableCutOffDays = [days.tue, days.fri];

const cutOffTimeSlotByDay = {
  [days.sun]: [],
  [days.mon]: [],
  [days.tue]: [{ hours: 18, minutes: 0 }],
  [days.wed]: [],
  [days.thu]: [],
  [days.fri]: [{ hours: 18, minutes: 0 }],
  [days.sat]: [],
};

export const getCutOffDates = ({ start, end }) => {
  let cutOffDates = [];

  availableCutOffDays.forEach((day) => {
    const days = getDaysBetween({
      start,
      end,
      day,
    });

    cutOffDates = [...cutOffDates, ...days];
  });

  return cutOffDates;
};

export const getCutOffTimesByDate = (date, { timezone } = {}) => {
  const dayOfWeek = datetime(date).day();

  const cutOffDayObj = datetime(date).toObject();

  const timeslots = cutOffTimeSlotByDay[dayOfWeek];

  return timeslots.map(({ hours, minutes }) => {
    const timeslotObj = {
      ...cutOffDayObj,
      hours,
      minutes,
      seconds: 0,
      milliseconds: 0,
    };

    return objectToZonedTime(timeslotObj, timezone);
  });
};

export const newFormatDeliveryDateTimeRange = (
  dateTime,
  { timezone } = {},
) => {
  const formattedDate = formatDate(dateTime, {
    timezone,
    format: 'DD MMM ’YY, ddd',
  });

  const formattedStartTime = formatTime(dateTime, { timezone });
  const formattedEndTime = formatTime(
    datetime(dateTime).add(DELIVERY_TIMESLOT_DURATION_HOURS, 'hours'),
    { timezone },
  );

  return {
    date: formattedDate,
    time: `${formattedStartTime} - ${formattedEndTime}`,
  };
};

export const formatDeliveryDateTimeRange = (
  dateTime,
  { timezone } = {},
) => {
  const formattedStartDatetime = formatDatetime(dateTime, {
    timezone,
  });

  const formattedEndTime = formatTime(
    datetime(dateTime).add(DELIVERY_TIMESLOT_DURATION_HOURS, 'hours'),
    { timezone },
  );

  return `${formattedStartDatetime} - ${formattedEndTime}`;
};

export const formatDeliveryTimeRange = (
  deliveryTime,
  { timezone } = {},
) => {
  const formattedStartTime = formatTime(deliveryTime, {
    timezone,
  });

  const formattedEndTime = formatTime(
    datetime(deliveryTime).add(
      DELIVERY_TIMESLOT_DURATION_HOURS,
      'hours',
    ),
    { timezone },
  );

  return `${formattedStartTime} - ${formattedEndTime}`;
};
