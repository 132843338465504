import datetime from './datetime';

export const DATE_FORMAT = 'DD-MM-YYYY';
export const DATETIME_FORMAT = 'DD-MM-YYYY, hh:mm a';
export const TIME_FORMAT = 'hh:mm a';

export const formatDate = (
  parsableDatetime,
  { timezone, format = DATE_FORMAT } = {},
) => datetime(parsableDatetime).tz(timezone).format(format);

export const formatDatetime = (parsableDatetime, { timezone } = {}) =>
  datetime(parsableDatetime).tz(timezone).format(DATETIME_FORMAT);

export const formatTime = (parsableDatetime, { timezone } = {}) =>
  datetime(parsableDatetime).tz(timezone).format(TIME_FORMAT);

/**
 * Get all days by day number between a given date range e.g. get all Tuesdays between 1/3/2021 to 1/4/2021
 * @param {Object} arguments
 * @param {string} arguments.start ISO date string for start date
 * @param {string} arguments.end  ISO date string for end date
 * @param {number} arguments.day Day number for the queried day, Sunday -> 0, Monday -> 1 and so on.
 * @returns {Object[]} An array of datetime instances
 */
export const getDaysBetween = ({ start, end, day }) => {
  const result = [];

  const parsedStart = datetime(start);
  const parsedEnd = datetime(end);

  const firstDayInWeek = parsedStart.day(day);

  let current = firstDayInWeek.isBefore(parsedStart, 'day')
    ? firstDayInWeek.add(7, 'days')
    : firstDayInWeek;

  while (
    current.isBefore(parsedEnd, 'day') ||
    current.isSame(parsedEnd, 'day')
  ) {
    result.push(datetime(current));
    current = current.add(7, 'days');
  }

  return result;
};

export const sortDateTimesAsc = (dateTimes) =>
  dateTimes.sort((a, b) => (a.isAfter(b) ? 1 : -1));

export const sortDateTimesDesc = (dateTimes) =>
  dateTimes.sort((a, b) => (b.isAfter(a) ? 1 : -1));

/**
 * to switch timezone without changing the local time
 * e.g. 2016-01-15T11:58:07-08:00 -> 2016-01-15T11:58:07-05:00
 * @param {*} parseableDatetime Any form of datetime that is parseable
 * @param {Object} context
 * @param {string} context.fromTimezone timezone name of the parseableDatetime
 * @param {string} context.toTimezone name of the timezone to change to
 * @returns {datetime.Dayjs} The datetime object that has the timezone changed but local time kept
 */
export const switchTimezoneButKeepLocalTime = (
  parseableDatetime,
  { fromTimezone, toTimezone },
) => {
  const zonedDateTime = datetime
    .tz(parseableDatetime, fromTimezone)
    .tz(toTimezone, true);

  return zonedDateTime;
};

/**
 * Guess the timezone of the current client
 * @returns the current client's timezone name e.g. Asia/Singapore
 */
export const getClientTimezone = () => datetime.tz.guess();

/**
 * Create a datetime instance from the provided object in the provided timezone
 * @param {{
 *  years: Number,
 *  months: Number,
 *  date: Number,
 *  hours: Number,
 *  minutes: Number,
 *  seconds: Number,
 *  milliseconds: Number
 * }} timeObject The object to create the datetime from
 * @param {String} timezone the name of the timezone to create the datetime in
 * @returns the datetime instance created in the specified timezone
 */
export const objectToZonedTime = (timeObject, timezone) => {
  return datetime().tz(timezone).set(timeObject);
};
